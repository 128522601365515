<template>
  <div>
    <Divider dashed><span class="divider-text">账单汇总</span></Divider>
    <Row class="text-center p-t-5 p-b-5">
      <i-col span="24" class="text-16">
        合同总额<span class="text-orange">{{
          formatMoney(statisticInfo.totalAmount)
        }}</span>
      </i-col>
      <i-col span="12" style="border: 1px #555 solid">
        <Row>
          <i-col span="24" style="background: #555; line-height: 35px"
            >已付款金额</i-col
          >
          <i-col style="line-height: 35px" span="24">
            <label class="title">总额</label
            ><label class="text-orange">{{
              formatMoney(statisticInfo.receivedAmount)
            }}</label>
          </i-col>
          <template >
            <i-col  span="24" style="border-top: 1px solid #ccc;text-align:left;padding:5px;" class="transaction-content">
              <p class="title">已退款</p>
              {{ formatMoney(statisticInfo.refundedAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;"  class="transaction-content">
              <p class="title">待退款</p>
              {{ formatMoney(statisticInfo.waitRefundAmount) }}
            </i-col>
          </template>
        </Row>
      </i-col>
      <i-col span="12" style="border: 1px #555 solid">
        <Row>
          <i-col span="24" style="background: #555; line-height: 35px"
            >待付款金额</i-col
          >
          <i-col style="line-height: 35px" span="24">
            <label class="title">总额</label
            ><label class="text-orange">{{
              formatMoney(statisticInfo.waitAmount)
            }}</label>
          </i-col>
          <template >
            <i-col  span="24" style="border-top: 1px solid #ccc;text-align:left;padding:5px;" class="transaction-content">
              <p class="title">逾期待收款金额</p>
              {{ formatMoney(statisticInfo.overdueAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">&nbsp;</p>
              &nbsp;
            </i-col>
          </template>
        </Row>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getbillstatistic } from '@/api/order/om/order'
export default {
  components: {
    // createComponent: () => import('@/components/advertiser/CreateAdvertiser')
  },
  data () {
    return {
      statisticInfo: {}
    }
  },
  created () {
    this.initStatistic()
  },
  methods: {
    formatMoney (value) {
      return toMoney(value)
    },
    initStatistic () {
      if (this.query.publisherId) {
        getbillstatistic(this.query).then((res) => {
          this.statisticInfo = res
        })
      }
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.openmarketbill.beginUpdate
    },
    query () {
      return this.$store.state.openmarketbill.query
    }
  },
  watch: {
    beginUpdate (val) {
      this.initStatistic()
    }
  }
}
</script>
